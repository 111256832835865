<template>
    <nuxt-link :id="blok._uid"
        :to="url"
        class="col-span-full flex h-full auto-cols-max flex-col bg-[color:var(--background-color)]
        text-[color:var(--color)] lg:grid lg:grid-cols-3"
        :class="{ 'my-0 size-full': inWideSlider, 'my-2xl md:mx-md 2xl:mx-0 ': !inWideSlider }"
        :style="{
            '--background-color': backgroundColor,
            '--color': fontColor,
        }"
        @click="addPromotionClick(promo)">
        <nuxt-picture class="col-span-2 hidden aspect-square w-full md:block md:aspect-video"
            :class="{
                'md:col-start-2': blok.content_alignment === 'left',
                'h-full': !inWideSlider,
                'block size-full w-full object-cover': inWideSlider,
            }"
            :src="blok.image.filename"
            provider="storyblok"
            loading="lazy"
            sizes="xs:100vw sm:640px md:768px lg:1024px xl:1280px 2xl:1536px"
            :img-attrs="{
                class: 'size-full object-cover aspect-square md:aspect-[3/2]'
            }"
            :alt="blok.image.alt ?? blok.title" />

        <nuxt-picture v-if="blok.image_mobile?.filename"
            class="col-span-2 block aspect-square w-full md:hidden"
            :class="{
                'h-auto': !inWideSlider,
                'block size-full w-full object-cover': inWideSlider,
            }"
            :src="blok.image_mobile?.filename"
            provider="storyblok"
            loading="lazy"
            sizes="xs:100vw sm:640px md:768px lg:1024px xl:1280px 2xl:1536px"
            :img-attrs="{
                class: 'size-full object-cover aspect-square md:aspect-[3/2]'
            }"
            :alt="blok.image_mobile?.alt ?? blok.title" />

        <div class="flex h-full flex-col justify-center
            gap-y-sm px-sm py-md md:gap-y-10 md:px-md"
            :class="{
                'col-start-1 row-start-1': blok.content_alignment === 'left',
                'col-span-1 h-auto': !inWideSlider,
                'size-full': inWideSlider,
            }">
            <div class="flex flex-col gap-x-sm md:mt-0 md:gap-y-sm lg:mt-sm xl:flex-row">
                <div v-show="blok.step_number"
                    class="relative flex size-10 rounded-full bg-red-500">
                    <p class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 font-prenton-cond text-5xl
                    text-white">
                        {{ blok.step_number }}
                    </p>
                </div>

                <p class="w-full font-prenton-cond text-5xl font-bold uppercase"
                    :class="{
                        'text-right': blok.text_alignment === 'right',
                        'text-center': blok.text_alignment === 'center',
                        'text-left': blok.text_alignment === 'left',
                    }">
                    {{ blok.title }}
                </p>
            </div>

            <div>
                <div class="prose font-sans text-base">
                    <sb-rich-text :document="blok.content" />
                </div>
            </div>

            <div v-if="url && blok.button_text"
                class="flex"
                :class="{
                    'justify-end': blok.text_alignment === 'right',
                    'justify-center': blok.text_alignment === 'center',
                    'justify-start': blok.text_alignment === 'left',
                }">
                <div class="inline-block rounded bg-black p-sm">
                    <v-button theme="black"
                        size="sm"
                        class="text-xs text-white md:text-base md:leading-5">
                        {{ blok.button_text }}
                    </v-button>
                </div>
            </div>
        </div>
    </nuxt-link>
</template>

<script setup lang="ts">
import type { CtaBlokComponent, BlokColorpicker } from '~~/types';

const properties = defineProps<{
    blok: CtaBlokComponent
}>();

const inWideSlider = inject('sb.inWideSlider', false);
const { format: formatSbUrl } = useSbUrl();

const url = computed(() => formatSbUrl(properties.blok.cta_link.cached_url));

const backgroundColor = computed<string | undefined>(() => (
    properties.blok?.background_color?.color ?? '#FFF'
));

const fontColor = computed<string | BlokColorpicker | undefined>(() => (
    properties.blok?.text_color ?? 'black'
));

const route = useRoute();
const { addPromotionClick } = usePromotion();

function convertString(string: string) {
    const words = string.split('-');
    const convertedString = words.map(((word) => word.charAt(0).toUpperCase() + word.slice(1))).join(' ');
    return convertedString;
}

const promo = computed(() => ({
    /* eslint-disable @typescript-eslint/naming-convention */
    creative_name: convertString(properties.blok.component),
    creative_slot: `${properties.blok.component} / 0`, // component name / index
    // eslint-disable-next-line no-underscore-dangle
    promotion_id: properties.blok._uid,
    promotion_name: properties.blok.name,
    source_url: route.fullPath,
    target_url: url.value,
    /* eslint-enable @typescript-eslint/naming-convention */
}));
</script>
